import type { CurrentMemberFragment } from 'generated/graphql';
import { useAppData } from '@modules/root';
import type { User } from '~/server/types';

export const isUserLoggedIn = (user: User): user is CurrentMemberFragment => {
    return user.__typename === 'Me';
};

export const useUser = (): User => {
    return useAppData().user;
};
